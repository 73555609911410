<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import { computed, watch } from 'vue';
    import { Navigation } from '@/router/navigation';
    import { useCompanyAction, useCompanyQuery } from '@/composables/company';
    import { useAsyncTask } from 'vue-concurrency';
    import AccountItem from '@/account/components/AccountItem.vue';
    import { useMarketQuery } from '@/composables/market';
    import { marketContainsProvision } from '@/util/marketContainsProvision';
    import { ProvisionType } from '@containex/portal-backend-dto';
    import { useLeadQuery } from '../composables/leads';

    const { t } = useI18n();
    const { isUserLoggedIn, username, isAdmin } = useAuthenticationQuery();
    const { isRetailer } = useCompanyQuery();
    const authenticationAction = useAuthenticationAction();
    const companyAction = useCompanyAction();
    const { market } = useMarketQuery();
    const { amountNewLeads } = useLeadQuery();

    const hasMarketRentalProvisionType = computed(() => {
        if (market.value != null) {
            return marketContainsProvision(market.value, ProvisionType.Rental);
        }
        return false;
    });

    const task = useAsyncTask(async () => {
        await authenticationAction.fetchCurrentCustomerIfMissing.perform();
    }).perform();

    watch(
        isUserLoggedIn,
        async (newIsUserLoggedIn) => {
            if (newIsUserLoggedIn) {
                await companyAction.findCustomerCompanyIfMissing();
            }
        },
        { immediate: true }
    );

    const accountItems = computed(() =>
        [
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.MY_ACCOUNT'),
                      icon: 'pi pi-user',
                      description: t('ACCOUNT.OVERVIEW_VIEW.MY_ACCOUNT_DESCRIPTION'),
                      navigationLocation: Navigation.AccountSettings,
                  },
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.ORDERS'),
                      icon: 'pi pi-shopping-bag',
                      description: hasMarketRentalProvisionType.value
                          ? t('ACCOUNT.OVERVIEW_VIEW.ORDERS_DESCRIPTION')
                          : t('ACCOUNT.OVERVIEW_VIEW.ORDERS_DESCRIPTION_NO_RENTAL'),
                      navigationLocation: Navigation.OrderList,
                  },
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.INVOICES'),
                      icon: 'pi pi-wallet',
                      description: t('ACCOUNT.OVERVIEW_VIEW.INVOICES_DESCRIPTION'),
                      navigationLocation: Navigation.InvoiceList,
                  },
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.OFFERS'),
                      icon: 'pi pi-envelope',
                      description: t('ACCOUNT.OVERVIEW_VIEW.OFFERS_DESCRIPTION'),
                      navigationLocation: Navigation.OfferList,
                  },
            !isRetailer.value || !isAdmin.value
                ? undefined
                : {
                      label:
                          amountNewLeads.value > 0
                              ? //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                `${t('ACCOUNT.OVERVIEW_VIEW.LEADS')} (${amountNewLeads.value})`
                              : t('ACCOUNT.OVERVIEW_VIEW.LEADS'),
                      icon: 'pi pi-info-circle',
                      description: t('ACCOUNT.OVERVIEW_VIEW.LEADS_DESCRIPTION'),
                      navigationLocation: Navigation.Leads,
                  },
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.ORDER_CONFIRMATIONS'),
                      icon: 'pi pi-folder-open',
                      description: hasMarketRentalProvisionType.value
                          ? t('ACCOUNT.OVERVIEW_VIEW.ORDER_CONFIRMATIONS_DESCRIPTION')
                          : t('ACCOUNT.OVERVIEW_VIEW.ORDER_CONFIRMATIONS_DESCRIPTION_NO_RENTAL'),
                      navigationLocation: Navigation.OrderConfirmationList,
                  },
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.DOWNLOADS'),
                      icon: 'pi pi-file-pdf',
                      description: t('ACCOUNT.OVERVIEW_VIEW.DOWNLOADS_DESCRIPTION'),
                      navigationLocation: Navigation.Downloads,
                  },
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.CTX_EMPLOYEES'),
                      icon: 'pi pi-comments',
                      description: t('ACCOUNT.OVERVIEW_VIEW.CTX_EMPLOYEES_DESCRIPTION'),
                      navigationLocation: Navigation.Employees,
                  },
            !isAdmin.value
                ? undefined
                : {
                      label: t('ACCOUNT.OVERVIEW_VIEW.USERS'),
                      icon: 'pi pi-users',
                      description: t('ACCOUNT.OVERVIEW_VIEW.USERS_DESCRIPTION'),
                      navigationLocation: Navigation.Users,
                  },
        ].filter((item) => item != null)
    );
</script>

<template>
    <template v-if="task.isSuccessful">
        <div class="account-overview-container">
            <h2 class="text-3xl-bold-line-height-auto">
                {{ t('ACCOUNT.OVERVIEW_VIEW.WELCOME', { name: username }) }}
            </h2>
            <p class="account-overview-description">
                {{ t('ACCOUNT.OVERVIEW_VIEW.DESCRIPTION') }}
            </p>
        </div>
        <h2 class="text-2xl-bold-line-height-auto account-item-title">{{ t('ACCOUNT.OVERVIEW_VIEW.MY_FUNCTIONS') }}</h2>
        <div class="account-item-list-wrapper">
            <template v-for="item in accountItems" :key="item">
                <AccountItem
                    :icon="item?.icon"
                    :description="item.description"
                    :label="item.label"
                    :navigation-location="item.navigationLocation"
                />
            </template>
        </div>
    </template>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .account-overview-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
        padding: main.$spacing-5 0 main.$spacing-7;
        border-bottom: 1px solid main.$color-border-gray;
    }

    @include main.for-breakpoint-md {
        .account-overview-container {
            padding: main.$spacing-6 0 48px;
        }
    }

    .account-item-title {
        padding-top: main.$spacing-7;
        padding-bottom: main.$spacing-6;
    }

    .account-overview-description {
        margin: 0;
    }

    .account-item-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .account-item-list-wrapper {
        display: grid;
        grid-template-columns: 100%;
        gap: main.$spacing-6;
        padding-bottom: main.$spacing-8;
    }

    @include main.for-breakpoint-md {
        .account-item-list-wrapper {
            grid-template-columns: 1fr 1fr;
        }
    }

    @include main.for-breakpoint-lg {
        .account-item-list-wrapper {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
</style>
